<template>
  <aside class="ui-aside" :class="{'ui-aside--close': isAsideClosed}">
    <button class="ui-aside__toggler" @click="isAsideClosed=!isAsideClosed">
      <font-awesome-icon icon="chevron-left" />
    </button>
    <div class="ui-aside__header" v-if="isActive(connection)">
      <CDropdown color="black" class="ui-account-switcher" :show.sync="show">
        <template #toggler-content>
          <div class="ui-account-switcher__icon">
            <font-awesome-icon icon="store" />
          </div>
          <div class="ui-account-switcher__label">
            {{ connection.family.name }}
          </div>
        </template>
        <CDropdownHeader class="px-2">
          <CInput :label="$t('common.search_label')" v-model="search">
            <template #append-content>
              <div class="cursor-pointer" @click="search = ''">
                <font-awesome-icon icon="times-circle" />
              </div>
            </template>
          </CInput>
        </CDropdownHeader>
        <CDropdownItem>
          <div
            class="d-flex justify-content-center m-2 w-100"
            v-if="activeConnections.length === 0"
          >
            <CSpinner color="info" />
          </div>
        </CDropdownItem>
        <CDropdownItem
          v-for="c in applySearch(activeConnections)"
          v-bind:key="c.family.id"
          @click.prevent="selectConnection(c)"
          :active="c.family.id === connection.family.id"
          >{{ c.family.name }}</CDropdownItem
        >
      </CDropdown>
    </div>

    <div class="ui-aside__body">
      <div class="ui-nav" v-if="canCollaborate(connection)">
        <div class="ui-nav__header">
          <div
            class="ui-nav__title"
            @click="collapse.cooperate = !collapse.cooperate"
            v-bind:class="{ 'ui-nav__title--close': collapse.cooperate }"
          >
            {{ $t('role.sidebar.label') }}
          </div>
        </div>
        <CCollapse class="ui-nav__body" :show="!collapse.cooperate">
          <ul>
            <li>
              <router-link
                :to="{
                  name: 'RoleDashboard',
                  params: { id: getRoleId(connection) },
                }"
                >{{ $t("role.sidebar.dashboard") }}</router-link
              >
            </li>
            <!-- DATA_CONTACTS -->
            <li v-if="canView(PERMS.DATA_CONTACTS_VIEW)">
              <a
                @click.prevent="
                  collapse.data_contacts = !collapse.data_contacts
                "
              >
                {{ $t("role.sidebar.data.title") }}
              </a>
              <CCollapse :show="!collapse.data_contacts">
                <ul>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleDataRole',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.data.admin") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleDataProfile',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.data.profile") }}</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link
                      :to="{
                        name: 'RoleDataCustomers',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.data.customers") }}</router-link
                    >
                  </li> -->
                </ul>
              </CCollapse>
            </li>

            <!-- PRODUCTS_AND_PRICES -->
            <li v-if="canView(PERMS.PRODUCTS_AND_PRICES_VIEW)">
              <a
                @click.prevent="
                  collapse.products_and_prices = !collapse.products_and_prices
                "
              >
                {{ $t("role.sidebar.products.title") }}
              </a>
              <CCollapse :show="!collapse.products_and_prices">
                <ul>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleProductsList',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.products.products") }}</router-link
                    >
                  </li>
                  <li v-if="canView(PERMS.PRODUCTS_AND_PRICES_MARKET_VIEW)">
                    <router-link
                      :to="{
                        name: 'RoleProductsShowcase',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.products.showcase") }}</router-link
                    >
                  </li>
                  <li v-if="canView(PERMS.PRODUCTS_AND_PRICES_MARKET_VIEW)">
                    <router-link
                      :to="{
                        name: 'RoleProductsSettings',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.products.settings") }}</router-link
                    >
                  </li>
                </ul>
              </CCollapse>
            </li>

            <!-- LOGISTICS -->
            <li v-if="canView(PERMS.LOGISTICS_VIEW)">
              <a @click.prevent="collapse.logistics = !collapse.logistics">
                {{ $t("role.sidebar.logistics.title") }}
              </a>
              <CCollapse :show="!collapse.logistics">
                <ul>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleLogisticsConnections',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{
                        $t("role.sidebar.logistics.connections")
                      }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleLogisticsPlanning',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.logistics.planning") }}</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link
                      :to="{
                        name: 'RoleLogisticsDelivery',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.logistics.delivery") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleLogisticsDeliveryOpConfiguration',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.logistics.settings") }}</router-link
                    >
                  </li> -->
                </ul>
              </CCollapse>
            </li>

            <!-- ORDERS_AND_REPORTS -->
            <li v-if="canView(PERMS.ORDERS_VIEW)">
              <a
                @click.prevent="
                  collapse.orders_and_reports = !collapse.orders_and_reports
                "
              >
                {{ $t("role.sidebar.orders.title") }}
              </a>
              <CCollapse :show="!collapse.orders_and_reports">
                <ul>
                  <li v-if="canView(PERMS.ORDERS_ROUND_MANAGEMENT)">
                    <router-link
                      :to="{
                        name: 'RoleOrdersRoundsList',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t('role.sidebar.orders.overview') }}</router-link
                    >
                  </li>
                  <li v-if="canView(PERMS.ORDERS_DDMP_MANAGEMENT)">
                    <router-link
                      :to="{
                        name: 'RoleOrdersManage',
                        params: { id: getRoleId(connection) },
                        query: { view: 'ddmp' },
                      }"
                      >{{ $t('role.sidebar.orders.producer') }}</router-link
                    >
                  </li>
                  <li v-if="canView(PERMS.ORDERS_DDMI_MANAGEMENT)">
                    <router-link
                      :to="{
                        name: 'RoleOrdersManage',
                        params: { id: getRoleId(connection) },
                        query: { view: 'ddmi' },
                      }"
                      >{{ $t('role.sidebar.orders.island') }}</router-link
                    >
                  </li>
                  <!-- <li>
                    <router-link
                      :to="{
                        name: 'RoleOrdersInProgress',
                        params: { id: getRoleId(connection) }
                      }"
                      >{{ $t("role.sidebar.orders.current") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleOrdersUpcoming',
                        params: { id: getRoleId(connection) }
                      }"
                      >{{ $t("role.sidebar.orders.incoming") }}</router-link
                    >
                  </li> -->
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleOrdersArchive',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.orders.archive") }}</router-link
                    >
                  </li>
                </ul>
              </CCollapse>
            </li>

            <!-- FINANCE_AND_ACCOUNTING -->
            <li v-if="canView(PERMS.FINANCE_AND_ACCOUNTING_VIEW)">
              <a
                @click.prevent="
                  collapse.finance_and_accounting =
                    !collapse.finance_and_accounting
                "
              >
                {{ $t("role.sidebar.finance.title") }}
              </a>
              <CCollapse :show="!collapse.finance_and_accounting">
                <ul>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleFinanceInvoicesOut',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{
                        $t("role.sidebar.finance.invoices_out")
                      }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleFinanceInvoicesIn',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.finance.invoices_in") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'RoleFinanceSettings',
                        params: { id: getRoleId(connection) },
                      }"
                      >{{ $t("role.sidebar.finance.settings") }}</router-link
                    >
                  </li>
                </ul>
              </CCollapse>
            </li>
          </ul>
        </CCollapse>
      </div>

      <div class="ui-nav">
        <div class="ui-nav__header">
          <div
            class="ui-nav__title"
            @click="collapse.buy = !collapse.buy"
            v-bind:class="{ 'ui-nav__title--close': collapse.buy }"
          >
            {{ $t('user.sidebar.label') }}
          </div>
        </div>
        <CCollapse class="ui-nav__body" :show="!collapse.buy">
          <ul>
            <li>
              <router-link
                :to="{
                  name: 'Home',
                }"
                >{{ $t("user.sidebar.dashboard") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'UserMarketList',
                  params: { familyId: getFamilyId(connection) },
                }"
                >{{ $t("user.sidebar.markets") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'UserMarketCarts',
                  params: { familyId: getFamilyId(connection) },
                }"
                >{{ $t("user.sidebar.carts") }}</router-link
              >
            </li>
            <li v-if="!canCollaborate(connection)">
              <router-link
                :to="{
                  name: 'UserMarketInvoices',
                  params: { familyId: getFamilyId(connection) },
                }"
                >{{ $t("user.sidebar.invoices") }}</router-link
              >
            </li>
            <li>
              <router-link
                :to="{
                  name: 'UserMarketOrders',
                  params: { familyId: getFamilyId(connection) },
                }"
                >{{ $t("user.sidebar.orders") }}</router-link
              >
            </li>
            <li v-if="false">
              <a
                @click.prevent="collapse.user_orders = !collapse.user_orders"
                >{{ $t("user.sidebar.orders") }}</a
              >
              <CCollapse :show="!collapse.user_orders">
                <ul>
                  <li>
                    <router-link
                      :to="{
                        name: 'UserOrdersInProgress',
                      }"
                      >{{ $t("user.sidebar.orders_current") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'UserOrdersArchive',
                      }"
                      >{{ $t("user.sidebar.orders_archive") }}</router-link
                    >
                  </li>
                  <li>
                    <router-link
                      :to="{
                        name: 'UserOrdersSubscription',
                      }"
                      >{{ $t("user.sidebar.orders_subscription") }}</router-link
                    >
                  </li>
                </ul>
              </CCollapse>
            </li>
          </ul>
        </CCollapse>
      </div>

      <div class="ui-nav" v-if="!canCollaborate(connection)">
        <div class="ui-nav__header">
          <div
            class="ui-nav__title"
            @click="collapse.cooperate = !collapse.cooperate"
            v-bind:class="{ 'ui-nav__title--close': collapse.cooperate }"
          >
            {{$t("role.sidebar.label")}}
          </div>
        </div>
        <CCollapse class="ui-nav__body" :show="!collapse.cooperate">
          <!-- <router-link
            class="ui-contribute ui-contribute--red"
            :to="{ name: 'UserNewRole' }"
          >
            <div class="ui-contribute__title">Diventa un mercato</div>
            <div class="ui-contribute__subtitle">
              Acquista e rivendi prodotti
            </div>
          </router-link> -->

          <router-link
            class="ui-contribute ui-contribute--green"
            :to="{ name: 'UserCollaborateNewProducer' }"
          >
            <div class="ui-contribute__title">{{ $t('role.sidebar.become_producer_label') }}</div>
            <div class="ui-contribute__subtitle">{{ $t('role.sidebar.become_producer_desc') }}</div>
          </router-link>

          <!-- <router-link
            class="ui-contribute ui-contribute--blue"
            :to="{ name: 'UserNewRole' }"
          >
            <div class="ui-contribute__title">Collabora alla logistica</div>
            <div class="ui-contribute__subtitle">
              Aiutaci nella consegna e ritiro
            </div>
          </router-link> -->
        </CCollapse>
      </div>
    </div>

    <div class="ui-aside__footer">
      <ul>
        <li>
          <hr class="m-0" />
        </li>
        <li>
          <router-link
            :to="{
              name: 'UserSettings',
            }"
            >{{ $t('user.sidebar.settings_label') }}</router-link
          >
        </li>
      </ul>
    </div>
  </aside>
</template>

<script>
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import { PERMS, isActive, haveRole } from "../helpers/acl";

export default {
  name: "UISidebar",

  data() {
    return {
      PERMS,
      connections: [],
      collapse: { ...this.setupCollapse() },
      show: false,
      search: "",
      isAsideClosed: false,
    };
  },

  watch: {
    show() {
      this.search = "";
    },
  },

  mounted() {
    if (window.innerWidth < 768) {
      this.isAsideClosed = true;
    }

    this.$store.dispatch("connections/fetchAll");
  },

  methods: {
    isActive,

    setupCollapse() {
      const { name } = this.$route;

      const collapse = {
        cooperate: false,
        buy: false,
        data_contacts: true,
        products_and_prices: true,
        orders_and_reports: true,
        logistics: true,
        reporting: true,
        finance_and_accounting: true,
        user_orders: true,
      };

      if (
        ["RoleDataRole", "RoleDataProfile", "RoleDataCustomers"].includes(name)
      ) {
        collapse.data_contacts = false;
      } else if (
        [
          "RoleProductsList",
          "RoleProductsShowcase",
          "RoleProductsSettings",
        ].includes(name)
      ) {
        collapse.products_and_prices = false;
      } else if (
        [
          "RoleOrdersRounds",
          "RoleOrdersRoundsList",
          "RoleOrdersManage",
        ].includes(name)
      ) {
        collapse.orders_and_reports = false;
      } else if (
        [
          "RoleLogisticsConnections",
          "RoleLogisticsPlanning",
          "RoleLogisticsDelivery",
          "RoleLogisticsDeliveryOpConfiguration",
        ].includes(name)
      ) {
        collapse.logistics = false;
      } else if (
        [
          "RoleFinanceInvoicesOut",
          "RoleFinanceInvoicesIn",
          "RoleFinanceSettings",
        ].includes(name)
      ) {
        collapse.finance_and_accounting = false;
      }

      return collapse;
    },

    canCollaborate(connection) {
      return isActive(connection) && haveRole(connection);
    },

    getRoleId(connection) {
      return get(connection, "family.role.id", undefined);
    },

    getFamilyId(connection) {
      return get(connection, "family.id", undefined);
    },

    selectConnection(connection) {
      this.$store
        .dispatch("connections/fetchById", connection.id)
        .then(({ connection, role }) => {
          this.$store.commit("setRole", role);
          this.$store.commit("connections/setCurrent", connection);
          if (role) {
            this.$router.push({
              name: "RoleDashboard",
              params: { id: role.id },
            });
          } else {
            this.$router.push({ name: "UserDashboard" });
          }
        });
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },

    applySearch(connections) {
      const { search } = this;
      if (search.length > 0) {
        return connections.filter((connection) => {
          return lowerCase(connection.family.name).includes(search);
        });
      }
      return connections;
    },
  },

  computed: {
    connection() {
      return this.$store.state.connections.current;
    },

    activeConnections() {
      return this.$store.getters["connections/activeConnections"];
    },
  },
};
</script>
